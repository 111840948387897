import DOMPurify from 'isomorphic-dompurify';
import Image from 'next/future/image';
import Link from 'next/link';

import { replaceWithRequiredSiteInfo } from '@/helper/wordReplace';
import { SITE_TITLE, SITE_URL } from '@/utils/constants';

// The title, description and link on the left and image on the right
// The description is hidden on phone device
const StorageSolutionCard = (props: any) => {
  const { card, hostUrl } = props;
  const { Title, Description, Thumbnail } = card;
  const linkText = card && card.Link_Text;
  const linkUrl = card && card.Link_Slug_or_URL ? card.Link_Slug_or_URL : '';
  const urlImg =
    Thumbnail &&
    Thumbnail.data &&
    Thumbnail.data.attributes &&
    Thumbnail.data.attributes.url;
  const urlImgAlt =
    Thumbnail &&
    Thumbnail.data &&
    Thumbnail.data.attributes &&
    Thumbnail.data.attributes.alternativeText;
  const brandUrl = hostUrl ? `https://${hostUrl}` : SITE_URL;
  const brandTitle = SITE_TITLE;

  return (
    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div className="card sv-solution-card mb-0">
        <div className="card-body p-0">
          <div className="row g-0">
            <div className="col-xl-8 col-lg-8 col-md-8 col-8">
              <div className="card-body-padding">
                <h5 className="card-title">{Title}</h5>
                <div
                  className="card-text"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      replaceWithRequiredSiteInfo(
                        brandTitle,
                        brandUrl,
                        Description
                      )
                    ),
                  }}
                ></div>
              </div>
              {linkText && linkUrl && (
                <div className="card-footer">
                  <Link
                    href={
                      replaceWithRequiredSiteInfo(
                        brandTitle,
                        brandUrl,
                        linkUrl
                      ) || ''
                    }
                    className="card-link link mt-2"
                  >
                    <a className="card-link link">{linkText}</a>
                  </Link>
                </div>
              )}
            </div>{' '}
            <div className="col-xl-4 col-lg-4 col-md-4 col-4">
              <Image
                src={urlImg}
                sizes="(max-width: 375px) 95px, (max-width: 576px) 135px, (max-width: 768px) 140px, (max-width: 992px) 125px, (max-width: 1280px) 145px"
                className="card-img"
                alt={urlImgAlt || 'location-img'}
                width="145"
                height="155"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorageSolutionCard;
